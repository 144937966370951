$(() => {
  const $userRegistrationForm = $("#register-form");
  const $userGroupFields      = $userRegistrationForm.find(".user-group-fields");
  const inputSelector         = 'input[name="user[sign_up_as]"]';
  const newsletterSelector    = 'input[type="checkbox"][name="user[newsletter]"]';
  const $newsletterModal      = $("#sign-up-newsletter-modal");
  const SingleName            = $(".group-single-name");
  const SingleSurnames        = $(".group-single-surnames");
  const SingleGender          = $(".group-gender");
  const Birth                 = $(".group-single-birth");
  const Dni                   = $(".group-single-dni");
  const nameGroup             = $("#registration_user_user_group_name");
  const nicknameGroup         = $("#registration_user_user_group_nickname");
  const emailGroup            = $("#registration_user_user_group_email");
  

  const setGroupFieldsVisibility = (value) => {
    if (value === "user") {
      $userGroupFields.hide();
      SingleName.show();
      SingleSurnames.show();
      SingleGender.show();
      Birth.show();
      Dni.show();
      nameGroup.prop('required',false);
      nicknameGroup.prop('required',false);
      emailGroup.prop('required',false);
    } else {
      $userGroupFields.show();
      SingleName.hide();
      SingleSurnames.hide();
      SingleGender.hide();
      Birth.hide();
      Dni.hide();
      nameGroup.prop('required',true);
      nicknameGroup.prop('required',true);
      emailGroup.prop('required',true);
    }
  }

  const checkNewsletter = (check) => {
    $userRegistrationForm.find(newsletterSelector).prop("checked", check);
    $newsletterModal.data("continue", true);
    $newsletterModal.foundation("close");
    $userRegistrationForm.submit();
  }

  setGroupFieldsVisibility($userRegistrationForm.find(`${inputSelector}:checked`).val());

  $userRegistrationForm.on("change", inputSelector, (event) => {
    const value = event.target.value;

    setGroupFieldsVisibility(value);
  });

  $userRegistrationForm.on("submit", (event) => {
    const newsletterChecked = $userRegistrationForm.find(newsletterSelector);
    if (!$newsletterModal.data("continue")) {
      if (!newsletterChecked.prop("checked")) {
        event.preventDefault();
        $newsletterModal.foundation("open");
      }
    }
  });

  $newsletterModal.find(".check-newsletter").on("click", (event) => {
    checkNewsletter($(event.target).data("check"));
  });
});
